import React, { useCallback, useEffect, useState, useRef } from 'react';
import { navigate, Redirect, Router, useLocation } from '@reach/router';
import { SAButton, SACard, SAFooter, SAText } from '@saux/design-system-react';
import { Auth, Hub } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import TagManager from 'react-gtm-module';
import { v4 as uuidv4 } from 'uuid';
import SmartBanner from 'react-smartbanner';
import {
  ResponsiveFeedbackButton,
  ResponsiveFeedbackContent,
  ResponsiveFeedbackHolder,
  StyledContentWrapper,
  StyledInnerContentWrapper,
  TopContextWrapper,
} from './App.styles';
import '../node_modules/react-smartbanner/dist/main.css';
import getConfig, { Env } from './aws_exports';
import {
  ContactPage,
  DisplayName,
  FeedbackModal,
  MicroFrontend,
  Navbar,
  PhoneConfirmationModal,
  ProfilePage,
  SearchBar,
  AppStartupModal,
  PAYGDisplayBanner,
  ReportPayrollWrapper,
} from './components';
import { ContactPageWrapper } from './components/contactPage/ContactPageStyles';
import { ProfilePageWrapper } from './components/profilePage/ProfilePageStyles';
import { FreshChat } from './util/freshchat';
import simpleGTMDataLayer from './util/GTMHelpers/simpleGTMDataLayer';
import { useFeatureFlag } from './util/hooks';
import { AmpRedirect } from './util/ampRedirect';
import useIsResponsive from './util/useIsResponsive';
import EsignAlert from './components/ESignAlert/EsignAlert';
import SACustomFooter from './components/SAFooter/SAFooter';
import { CPRAFooter } from './util/CPRAFooter/CpraFooter';
import { formatDatePayg } from './util';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);
const gtmId = awsExports.gtmID;

interface AppProps {
  skipSignIn?: boolean;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

function redirectToLoginPage(error: string) {
  if (error.includes('not authenticated')) {
    if (window.location.search.indexOf('?code=') === -1) {
      sessionStorage.setItem('redirectLocation', window.location.href);
      Auth.federatedSignIn({
        customProvider: 'EmpowerId',
      }).catch((_err) => {
        console.error('error signing in', _err);
      });
    }
  }
}

function checkAuthenticationStatus(
  isInvalidUser: boolean,
  signedIn: boolean,
): string | null {
  if (isInvalidUser) {
    return 'Invalid user.';
  }
  if (!signedIn) {
    return 'Please wait. Signing in...';
  }
  return null;
}

const microFrontendEndpoint: {
  overview: string;
  policy: string;
  billingPayments: string;
  claims: string;
  documents: string;
  communications: string;
} = {
  overview: awsExports.micro_frontend_endpoint.overview,
  policy: awsExports.micro_frontend_endpoint.policy,
  billingPayments: awsExports.micro_frontend_endpoint.billingPayments,
  claims: awsExports.micro_frontend_endpoint.claims,
  documents: awsExports.micro_frontend_endpoint.documents,
  communications: awsExports.micro_frontend_endpoint.communications,
};
const App = ({ skipSignIn = false, awsAppSyncClient }: AppProps) => {
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState<string>();
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [userLineOfBusiness, setUserLineOfBusiness] = useState<string>('');
  const [isInvalidUser, setIsInvalidUser] = useState<boolean>(false);
  const [sourceSystemCode, setSourceSystemCode] = useState<string>('GWPL');
  const [loading, setLoading] = useState<boolean>(true);
  const [paygAuditDueDate, setAuditDueDate] = useState<string | null>('');
  const [paygAuditPStartDate, setAuditPStartDate] = useState<string | null>('');
  const [paygAuditPEndDate, setAuditPEndDate] = useState<string | null>('');
  const [jobNumber, setJobNumber] = useState<string>('');
  const isResponsive = useIsResponsive();

  const openFeedbackModal = useRef<Function>();

  const { flagDetails: PhoneConfirmModal } = useFeatureFlag(
    'PhoneConfirmation',
  );
  const { flagDetails: MobileAppLinks } = useFeatureFlag('MobileAppLinks');
  const { flagDetails: AllCLAMPRedirect } = useFeatureFlag('AllCLAMPRedirect');
  const { flagDetails: EsignEmail } = useFeatureFlag('EsignEmail');
  const { flagDetails: PAYGBannerFromDDB } = useFeatureFlag('PAYGBannerFromDDB');
  const { flagDetails: CaliforniaPrivacyRights } = useFeatureFlag(
    'CaliforniaPrivacyRights',
  );

  const cleanAuditDate = (auditDate: string) => {
    if (auditDate.indexOf('T') >= 0) {
      return auditDate.split('T')[0];
    }
    return auditDate;
  };

  const ampRedirect = new AmpRedirect(awsAppSyncClient);
  useEffect(() => {
    if (accountNumber) {
      console.log('PAYG - PAYGBannerFromDDB: ', PAYGBannerFromDDB);
      if (PAYGBannerFromDDB && PAYGBannerFromDDB?.enabled) {
        console.log('PAYG Banner getting data from Dynamo DB is near realtime');
        ampRedirect.getPayAsYouGoStatus(accountNumber).then((res) => {
          const policies = res?.data?.account?.items[0]?.policies?.items;
          if (policies && policies.length > 0) {
            policies.forEach((policy: any) => {
              const payAsYouGoItems = policy?.payasyougo?.items || [];
              if (payAsYouGoItems.length > 0) {
                payAsYouGoItems.forEach((item: any) => {
                  if (!item.received_date || item.received_date <= 0) {
                    setAuditDueDate(cleanAuditDate(item.due_date));
                    setAuditPStartDate(
                      cleanAuditDate(item.audit_periodstartdate),
                    );
                    setAuditPEndDate(cleanAuditDate(item.audit_periodenddate));
                  }
                });
              }
            });
          }
        });
      } else {
        console.log('PAYG Banner getting data from GWPC in realtime');
        const userToken = sessionStorage.getItem('EIDuserName_PC') ?? ''
        ampRedirect.fetchInfo(userToken).then((res: any) => {
          if (
            res &&
            res.data &&
            res.data.getAccountPolicySummaries &&
            res.data.getAccountPolicySummaries.result &&
            res.data.getAccountPolicySummaries.result !== 'job number not found'
            ) {
              const result = JSON.parse(res?.data?.getAccountPolicySummaries?.result);  
              if (
                result.periodStartDate &&
                result.periodEndDate &&
                result.dueDate
                ) {
              setAuditDueDate(formatDatePayg(result?.dueDate));
              setAuditPStartDate(formatDatePayg(result?.periodStartDate));
              setAuditPEndDate(formatDatePayg(result?.periodEndDate));
            }
          }
        });
      }
      setLoading(false);
    } else setLoading(false);
  }, [PAYGBannerFromDDB]);

  const feedbackModalLink = (openModal: Function) => {
    openFeedbackModal.current = () => {
      document.body.style.overflow = 'hidden';
      openModal();
    };
  };

  const doSignIn = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const userType = user.attributes['custom:userType'];
        const redirectLocation = sessionStorage.getItem('redirectLocation');
        if (userType === 'A') {
          const redirectPath = redirectLocation
            ? new URL(redirectLocation).pathname
            : window.location.pathname;
          sessionStorage.removeItem('redirectLocation');
          window.location.assign(`${awsExports.agency_360_url}${redirectPath}`);
          return;
        }
        user.getSession(async function (err: any, session: any) {
          const userLOB = () => {
            if (session.idToken.payload.sourceSystemCode) {
              if (session.idToken.payload.sourceSystemCode === 'GWPL')
                return 'Personal Lines';
              if (session.idToken.payload.sourceSystemCode === 'GWCL')
                return 'Commercial Lines';
              return session.idToken.payload.sourceSystemCode;
            }
            return '';
          };

          setAccountNumber(
            session.idToken.payload.account_number ?? '0000000001',
          ); // NOTE: Leaving this account number as the default "Test-Specific Account in NonProd"
          setIsSuperUser(
            session.idToken.payload?.s
              ? session.idToken.payload.s.includes('*')
              : false,
          );
          setUserName(
            `${session.idToken.payload?.name ?? ''} ${
              session.idToken.payload?.given_name ?? 'N/A'
            }`,
          );
          setUserId(session.idToken.payload?.identities[0].userId ?? 'N/A');
          setUserEmail(session.idToken.payload?.email ?? 'N/A');

          if (!sessionStorage.getItem('accountNumber'))
            sessionStorage.setItem(
              'accountNumber',
              session.idToken.payload.account_number ?? '',
            );

          sessionStorage.setItem(
            'EIDuserName',
            session?.idToken?.payload?.identities[0]?.userId?.toLowerCase() ??
              '',
          );
          sessionStorage.setItem(
            'EIDuserName_PC',
            session?.idToken?.payload?.identities[0]?.userId ??
              '',
          );
          sessionStorage.setItem(
            'userName',
            `${session.idToken.payload?.name ?? ''} ${
              session.idToken.payload?.given_name
            }`,
          );
          sessionStorage.setItem(
            'userEmail',
            session.idToken.payload?.email ?? '',
          );
          sessionStorage.setItem('userLOB', userLOB());
          setUserLineOfBusiness(sessionStorage.getItem('userLOB') ?? '');
          FreshChat.initialize(
            awsAppSyncClient,
            user,
            session.idToken.payload.account_number ?? '',
            session.idToken.payload.sourceSystemCode ?? '',
          );
          CPRAFooter.initialize();
        });

        if (process.env.REACT_APP_ENV === Env.Dev) {
          window.snowplow = () => {};
        }

        TagManager.initialize({
          gtmId,
          dataLayer: {
            csrId: user.username,
          },
        });

        const title = window.location.pathname.substr(1);

        simpleGTMDataLayer({
          event: 'pageview',
          page: {
            path: window.location.pathname,
            title: `Customer360: ${
              title.charAt(0).toUpperCase() + title.slice(1)
            }`,
          },
        });
        setSignedIn(true);
      })
      .catch((error) => {
        redirectToLoginPage(error);
      });
  }, []);

  const doRedirectToRequestedPage = useCallback(() => {
    const redirectLocation = sessionStorage.getItem('redirectLocation');
    if (redirectLocation) {
      window.location.assign(redirectLocation);
    }
    sessionStorage.removeItem('redirectLocation');
  }, []);

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  const authenticationStatus: string | null = checkAuthenticationStatus(
    isInvalidUser,
    signedIn,
  );

  const searchedAccountNumber:
    | string
    | undefined
    | null = sessionStorage.getItem('accountNumber');

  const location = useLocation();

  useEffect(() => {
    if (skipSignIn) {
      setSignedIn(true);
      return;
    }
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signOut':
          setSignedIn(false);
          break;
        case 'signIn_failure':
          {
            const { message } = payload.data;
            if (message === 'invalid_grant') {
              setIsInvalidUser(true);
            } else if (message === 'invalid_request') {
              doRedirectToRequestedPage();
            }
          }
          break;
        default:
      }
    });
  }, [skipSignIn]);

  useEffect(() => {
    if (skipSignIn) {
      return;
    }
    if (signedIn) {
      doRedirectToRequestedPage();
    } else {
      doSignIn();
    }
  }, [signedIn, skipSignIn]);

  useEffect(() => {
    if (
      searchedAccountNumber !== null &&
      searchedAccountNumber !== undefined &&
      searchedAccountNumber?.length === 10
    ) {
      setAccountNumber(searchedAccountNumber);
    }
  }, [accountNumber, searchedAccountNumber]);

  useEffect(() => {
    sessionStorage.setItem(
      'productTourSession',
      JSON.stringify({
        currentPage: 'overview',
        displayTour: false,
        totalSteps: 5,
        currentStep: 0,
      }),
    );
  }, []);

  const showFreshChat: Function = () => {
    if (document.getElementById('fc_widget') && window.fcWidget) {
      window.fcWidget.show();
    }
  };

  const hideFreshChat: Function = () => {
    if (document.getElementById('fc_widget') && window.fcWidget) {
      window.fcWidget.hide();
    }
  };

  const useNavigation = (path: string) => navigate(path);

  const Overview: any = () => {
    showFreshChat();

    return (
      <>
        <MicroFrontend
          host={microFrontendEndpoint.overview}
          id="Overview"
          session={{
            accountNumber,
            awsAppSyncClient,
            useNavigation,
            sourceSystemCode,
          }}
        />
        {isResponsive && (
          <ResponsiveFeedbackHolder>
            <SACard variant="minimal">
              <ResponsiveFeedbackContent>
                <SAText text="How was your experience?" type="heading-3" />
              </ResponsiveFeedbackContent>
              <ResponsiveFeedbackButton>
                <SAButton
                  fullWidth
                  label="LEAVE FEEDBACK"
                  onClick={() => openFeedbackModal!.current!()}
                  variant="primary-large"
                  className="leave-feedback-button"
                />
              </ResponsiveFeedbackButton>
            </SACard>
          </ResponsiveFeedbackHolder>
        )}
      </>
    );
  };

  const Policy: any = () => {
    showFreshChat();

    return (
      <MicroFrontend
        host={microFrontendEndpoint.policy}
        id="Policy"
        session={{
          accountNumber,
          awsAppSyncClient,
          useNavigation,
        }}
      />
    );
  };

  const Claims: any = () => {
    hideFreshChat();
    return (
      <MicroFrontend
        host={microFrontendEndpoint.claims}
        id="Claims"
        session={{ accountNumber, awsAppSyncClient, useNavigation }}
      />
    );
  };

  const Billing: any = () => {
    showFreshChat();
    return (
      <MicroFrontend
        host={microFrontendEndpoint.billingPayments}
        id="BillingPayments"
        session={{
          accountNumber,
          awsAppSyncClient,
          sourceSystemCode,
        }}
      />
    );
  };

  const Documents: any = () => {
    showFreshChat();
    return (
      <MicroFrontend
        host={microFrontendEndpoint.documents}
        id="Documents"
        session={{ accountNumber, awsAppSyncClient, useNavigation }}
      />
    );
  };

  const superUserDisplayName = (
    <DisplayName accountNumber={accountNumber} client={awsAppSyncClient} />
  );

  const displayName = (
    <DisplayName
      accountNumber={accountNumber}
      client={awsAppSyncClient}
      displayName={userName}
    />
  );

  const Profile: any = () => {
    return (
      <ProfilePage
        awsAppSyncClient={awsAppSyncClient}
        accountNumber={accountNumber}
        displayName={displayName}
        userId={userId}
      />
    );
  };

  const Contact: any = () => {
    return (
      <ContactPage
        accountNumber={accountNumber}
        awsAppSyncClient={awsAppSyncClient}
      />
    );
  };

  const PhoneConfirmation = () => {
    return PhoneConfirmModal?.enabled &&
      !userLineOfBusiness.includes('Commercial') ? (
      <PhoneConfirmationModal
        awsAppSyncClient={awsAppSyncClient}
        accountNumber={accountNumber}
        userName={userName}
      />
    ) : (
      <></>
    );
  };

  const SearchBarWrap = () => (
    <SearchBar
      isSuperUser={isSuperUser}
      signOut={signOut}
      displayName={userName}
    />
  );

  const FeedbackModalWrap = () => (
    <FeedbackModal
      accountNumber={accountNumber}
      awsAppSyncClient={awsAppSyncClient}
      userName={userName}
      userEmail={userEmail}
      feedbackModalLink={feedbackModalLink}
    />
  );

  const ReportPayrollPage: any = () => {
    return (
      <>
        {accountNumber && (
          <ReportPayrollWrapper
            awsAppSyncClient={awsAppSyncClient}
            accountNumber={accountNumber}
            job_number={jobNumber}
          />
        )}
      </>
    );
  };

  if (location.pathname === '/profile') {
    return (
      <>
        <SearchBarWrap />
        <FeedbackModalWrap />
        <StyledContentWrapper>
          <ProfilePageWrapper>
            <Router>
              <Profile path="/profile" />
            </Router>
          </ProfilePageWrapper>
        </StyledContentWrapper>
      </>
    );
  }

  if (location.pathname === '/contact') {
    return (
      <>
        <SearchBarWrap />
        <FeedbackModalWrap />
        <StyledContentWrapper>
          <ContactPageWrapper>
            <Router>
              <Contact path="/contact" />
            </Router>
          </ContactPageWrapper>
        </StyledContentWrapper>
      </>
    );
  }

  if (!loading) {
    return (
      <>
        {MobileAppLinks?.enabled &&
          isResponsive &&
          !userLineOfBusiness.includes('Commercial') && (
            <SmartBanner title="State Auto Mobile" />
          )}
        {isSuperUser ? (
          <TopContextWrapper>
            <SearchBarWrap />
          </TopContextWrapper>
        ) : (
          <>
            <PhoneConfirmation />
            <SearchBarWrap />
          </>
        )}
        <FeedbackModalWrap />
        <AppStartupModal
          awsAppSyncClient={awsAppSyncClient}
          accountNumber={accountNumber}
        />
        {EsignEmail?.enabled && (
          <EsignAlert
            awsAppSyncClient={awsAppSyncClient}
            accountNumber={accountNumber}
          />
        )}
        <StyledContentWrapper>
          {authenticationStatus !== null ? (
            <SAText type="heading-3" text={authenticationStatus} />
          ) : (
            <div>
              {isSuperUser ? superUserDisplayName : displayName}
              {paygAuditDueDate && paygAuditPStartDate && paygAuditPEndDate && (
                <PAYGDisplayBanner
                  auditDueDate={paygAuditDueDate}
                  auditStartDate={paygAuditPStartDate}
                  auditEndDate={paygAuditPEndDate}
                />
              )}
              <StyledInnerContentWrapper>
                <Navbar
                  accountNumber={accountNumber ?? ''}
                  awsAppSyncClient={awsAppSyncClient}
                />
                <Router>
                  <Redirect from="/" to="/overview" noThrow />
                  <Overview path="/overview" />
                  <Policy path="/policy" />
                  <Billing path="/billing" />
                  <Claims path="/claims" />
                  <Documents path="/documents" />
                  <ReportPayrollPage path="/report-payroll" />
                </Router>
                <div style={{ marginTop: '20px', marginBottom: '-50px' }}>
                  <SACustomFooter
                    CARightsRequired={CaliforniaPrivacyRights?.enabled}
                  />
                </div>
              </StyledInnerContentWrapper>
            </div>
          )}
        </StyledContentWrapper>
      </>
    );
  }

  return <></>;
};

App.defaultProps = {
  skipSignIn: false,
};

export default App;
