import React, { useState } from 'react';

import {
  SAAlert,
  SASnackbar,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAUX360Theme,
} from '@saux/design-system-react';

import useIsResponsive from '../../../util/useIsResponsive';

import {
  FeedbackModalContainer,
  TitleRow,
  TitleText,
  CloseButton,
  DisclaimerWrap,
  DisclaimerNote,
  DisclaimerContactUs,
  FeedbackAlertWrap,
  ButtonsRow,
  CancelButton,
  ShareButton,
} from './FeedbackModalBodyStyles';

import { topicOptions, SelectGroup, TextareaGroup } from './fieldGroup';

import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

export interface Props {
  submit: Function;
  closeModal: Function;
  error: boolean;
}

const FeedbackModalBody = ({ submit, closeModal, error }: Props) => {
  const [topic, setTopic] = useState<string>(topicOptions[0]);
  const [topicError, setTopicError] = useState<boolean>(false);
  const [details, setDetails] = useState<string>('');
  const [detailsError, setDetailsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const submitFeedback = () => {
    let errorFlag = false;
    let errorMessage = '';

    if (topic === topicOptions[0]) {
      errorFlag = true;
      errorMessage = 'The topic field cannot be blank.';
      setTopicError(true);
    }

    if (!details?.length) {
      errorFlag = true;
      errorMessage = errorMessage
        ? 'All fields are required.'
        : 'The message field cannot be blank.';
      setDetailsError(true);
    }

    setErrorMsg(errorMessage);

    if (!errorFlag) {
      submit(details, topic);

      sessionStorage.setItem('feedbackDetails', details);
      simpleGTMDataLayer({
        event: `LeaveFeedbackSubmit-click`,
        event_action: 'Feedback Submit Button Click',
        event_category: 'Feedback',
        event_label: 'Feedback Submit Button Click',
      });

      simpleGTMDataLayer({
        event: `Snowplow-LeaveFeedbackSubmit-click`,
        event_action: 'Snowplow Feedback Submit Button Click',
        event_category: 'Snowplow Feedback',
        event_label: 'Snowplow Feedback Submit Button Click',
        event_logrocketURL: '',
        event_feedback_payload: details,
      });
    }
  };

  return (
    <FeedbackModalContainer className={error ? 'error' : ''}>
      <TitleRow>
        <TitleText>
          How easy was it to find the information you needed today?
        </TitleText>
        <CloseButton onClick={() => closeModal()}>
          <SAIcon
            icon={SAIcons.x}
            size={SAIconSize.medium}
            colorVariant={SAUX360Theme.color.dark.background}
          />
        </CloseButton>
      </TitleRow>
      <DisclaimerWrap>
        <DisclaimerNote>Note:</DisclaimerNote> This section is not intended to
        assist you with account-related issues. Please visit the&nbsp;
        <DisclaimerContactUs href="/contact">Contact Us</DisclaimerContactUs>
        &nbsp;for account help.
      </DisclaimerWrap>
      {error && (
        <FeedbackAlertWrap>
          <SAAlert severity="error">
            An error occurred when trying to send your feedback. Please try
            again.
          </SAAlert>
        </FeedbackAlertWrap>
      )}
      {(topicError || detailsError) && (
        <FeedbackAlertWrap>
          <SAAlert severity="warning">{errorMsg}</SAAlert>
        </FeedbackAlertWrap>
      )}
      <SelectGroup
        value={topic}
        error={topicError}
        onChange={(value: string) => {
          if (topicError) {
            setTopicError(false);
          }
          setTopic(value);
        }}
      />
      <TextareaGroup
        value={details}
        error={detailsError}
        onChange={(value: string) => {
          if (detailsError) {
            setDetailsError(false);
          }

          setDetails(value);
        }}
      />
      <ButtonsRow>
        <CancelButton className="cancel" onClick={() => closeModal()}>
          CANCEL
        </CancelButton>
        <ShareButton onClick={() => submitFeedback()}>SHARE</ShareButton>
      </ButtonsRow>
    </FeedbackModalContainer>
  );
};

export const FeedbackSuccessToast = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: Function;
}) => {
  const isResponsive = useIsResponsive();

  return (
    <SASnackbar
      open={isOpen}
      variant="snackbar-success"
      position="top"
      subtitle="We&#8217;ve received your feedback, thank you."
      closeable
      onClose={close}
      autoHideDuration={10000}
      zIndex={9999}
      offset={[0, isResponsive ? 70 : 98]}
    />
  );
};

export default FeedbackModalBody;
